import React, {useState} from 'react';
import classes from '../OpenContentPolicy/OpenContentPolicy.module.css';
import styles from './EditorialStandards.module.css';


function EditorialStandards(){

    const data=[
        {
            title:"Write original and substantial articles",
            text:"Articles must be original and not consist of text substantially copied, rehashed, plagiarised, adapted, aggregated, or syndicated from other sources. They should add value to NewsNow and be materially different from those that are already on NewsNow.",
            showMore:false,
            moreText:"Content that is suitable includes exclusive news, current news rehashed with added value, round-ups of multiple sources with added value, comment, opinion pieces, columns, feature articles, analytical articles, investigative pieces, interviews and reviews. From sports sites, we also welcome match previews, match reports and player ratings.Text articles should contain at least 150 words of original editorial. Multimedia articles (where the main content is video, audio or images) should contain at least 75 words of original text. Quotes copied from other publications/social media are not counted."
        },
        {
            title:"Substantiate claims, and use credible sources",
            text:"Claims (including rumours) must be substantiated. Where claims are substantiated with reference to a source, the source must be credible (and attributed — see below).",
            showMore:false,
            moreText:"(For avoidance of doubt, an assertion is not a claim if it is common knowledge. To be considered common knowledge, a piece of information must have been previously verified by numerous credible sources and be widely known within the relevant community.) You must take care and exercise good judgement when assessing the credibility of a source, even if the source is considered authoritative. Particular care should be taken when considering the credibility of anonymous sources, including those posting on social media or message boards, or referenced on other non-authoritative publications. If there exists reason to doubt a claim or the authenticity of a quote, or if after tracing it to its origin you find a claim or quote to have been misrepresented, then you should not report it as fact."
        },
        {
            title:"Attribute claims and quotes",
            text:"Attribution allows readers to verify the origin and legitimacy of the factual content of your articles. Where claims are substantiated with reference to a source, the source must be clearly attributed. Quotes must always be clearly attributed to their sources.",
            showMore:false,
            moreText: "Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris."
        },
        {
            title:"Proof-read and fact-check your work",
            text:"You should take reasonable steps to avoid obvious mistakes in your spelling, grammar and punctuation. Your prose should read well, and the message of your articles should be well constructed and easy to follow.",
            showMore: false,
            moreText: "Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris."

        },
        {
            title:"Write relevant headlines",
            text:"Articles must feature relevant headlines, which must be factually accurate and not sensational or misleading. NewsNow users must be given fair expectations of an article’s content when deciding which headlines to click on.",
            showMore: false,
            moreText: "Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris."
        },
        {
            title:"Provide article metadata",
            text:"Each article must contain a byline, stating the name of the author. It must also include the date and, if possible, time of publication.",
            showMore: false,
            moreText: "Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris."
        },
        {
            title:"Provide ‘About us’ and ‘Contact us’ pages",
            text:"Your publication must contain: an ‘About Us’ page, to provide background on your publication to your readers; and a ‘Contact’ page, to allow readers to provide feedback.",
            showMore: false,
            moreText: "Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris."
        }
    ]

    const [editorialData, setEditorialData]=useState(data);


    const showMoreOrLess =(idx)=>{
        const editorialDataCopy=[...editorialData];
        if(editorialDataCopy[idx].showMore){
            editorialDataCopy[idx].showMore =false
        }
        else{
            editorialDataCopy[idx].showMore =true
        }
        setEditorialData(editorialDataCopy)
    }




    const renderData=editorialData.map((item, index)=>(
        <li key={index}>
            <div className={styles.liTitle}>{item.title}</div>
            <div className={styles.liText}>{item.text}
                {!item.showMore &&
               <span className={classes.underlined}
                     onClick={()=>showMoreOrLess(index)}> Read More</span>}
            </div>
            {item.showMore && <div className={styles.liText}>{item.moreText}
                <span className={classes.underlined}
                onClick={()=>showMoreOrLess(index)}> Read Less</span></div>}
        </li>
    ))

    return(
        <div className='container'>
            <div className={classes.row}>
                <div className={styles.left}>
                    <div className={styles.single}>
                        <div className={classes.title}>Editorial Standards, Code of Conduct and Issue Resolution Procedures</div>
                        <div className={classes.text}>In order to maintain a high quality experience for our readers, NewsNow 
                        operates a strict set of Editorial Standards, Code of Conduct and Issue Resolution Procedures for publishers.</div>
                    </div>
                    <div className={styles.single}>
                        <div className={classes.title}>Editorial Standards</div>
                        <div className={styles.subTitle}>Background</div>
                        <div className={classes.text}>The credibility and quality of NewsNow as a news outlet is dependent on that of the websites it links to.</div>
                        <div className={classes.text}>Our readers expect the inclusion of publications on NewsNow that have an established reputation for quality and/or
                         breaking exclusive news stories through regular access to primary sources (e.g. in football, this means industry insiders such as
                          players, teams and their representatives). We classify such publications as authoritative, examples of which can be found among
                           the press, broadcasters, officially authorised media, and the wider online media.</div>
                        <div className={classes.text}>This document sets out specific guidelines for non-authoritative publications to help them achieve this. These
                         guidelines are designed to help deliver a high quality experience for users, leading to increased use of NewsNow and member
                          publishers’ websites.</div>
                          <ol className={styles.dataStyle}>
                            {renderData}
                          </ol>
                    </div>
                </div>
                <div className={styles.left}>
                    <div className={styles.single}>
                        <div className={classes.title}>Code of Conduct</div>
                        <div className={classes.text}>The following practices are considered grounds for immediate initiation of our Issues Resolution Procedures (detailed below):</div>
                        <ol className={styles.dataStyle}>
                            <li className={styles.list}>Attempting to manipulate in which sections of NewsNow links to your articles will appear, the ranking of articles within our Top Stories, or otherwise attempting to manipulate our systems.</li>
                            <li className={styles.list}>Causing any duplicate articles to be published on NewsNow (such as any article published under more than one URL or on a URL that has been changed). If you need to republish an article with an updated headline, you must do so on the original URL. If you cannot do this, or if your updated headline is not automatically picked up by our systems, you must contact us first to have the original headline and URL deleted from NewsNow. Only after the original has been deleted may you republish the article under a new URL. Deleting the original article from your site or your feed will not result in it automatically disappearing from NewsNow. Republishing that leads to duplication may be considered an attempt to manipulate our systems.</li>
                            <li className={styles.list}>Causing any off-topic articles to be published on NewsNow, including but not limited to competitions, offers or discounts, updates about one’s own website, or advertorial, or articles containing any editorial written purely for the purpose of providing paid links (such as to betting websites).</li>
                            <li className={styles.list}>Using sectarian, racist, defamatory or libellous language; hate speech; excessive or inappropriate profanity or insulting language. Content must abide by the laws of England and Wales, or any other relevant jurisdiction.</li>
                            <li className={styles.list}>Displaying an excessive amount of advertising, or advertising that is otherwise overly obtrusive, impinging on the reading of articles, or for a product or service unsuitable for our audience.</li>
                            <li className={styles.list}>Serving article pages that are excessively large in download size and/or are poorly optimised for either desktop or mobile devices.</li>
                            <li className={styles.list}>Moving or removing the NewsNow logo from its agreed position on your site, or altering its appearance or implementation, except by prior arrangement.</li>
                            <li className={styles.list}>Any action or activity that in our sole judgement may affect the perceived quality or integrity of NewsNow’s services or that risks bringing NewsNow or its services into disrepute.</li>
                        </ol>
                    </div>
                    <div className={styles.single}>
                        <div className={classes.title}>Issue Resolution Procedures</div>
                        <div className={classes.text}>NewsNow aims to provide the highest possible quality of service, and to maintain an environment suitable for all. We therefore endeavour to investigate all serious issues or credible complaints that are brought to our attention, and to take appropriate action.</div>
                        <div className={classes.text}>Where we have an immediate quality of service concern, we will normally remove one or more headlines, publication sections, or an entire publication, without notice and until the matter has been resolved.</div>
                        <div className={classes.text}>For very serious breaches or for gross misconduct, we reserve the right to remove a publication permanently and without notice.</div>
                        <div className={classes.text}>Where we identify other apparent breaches of our Editorial Standards or Code of Conduct, we will normally request a written explanation from the publisher. Where a publisher cannot provide a satisfactory explanation, sanctions will be applied. Depending on the severity of the breach, or the persistence of breaches, sanctions may include: written warning, temporary suspension (for a period of between one week and several months depending on circumstances), reclassification (changing where a site’s headlines appear on NewsNow) and permanent removal.</div>
                        <div className={styles.boldText}>NewsNow reserves the right to amend or improve its Editorial Standards, Code of Conduct and Issue Resolution Procedures at any time without notice.</div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}

export default EditorialStandards;