import React from "react";
import classes from './RemoteEditor.module.css';


function RemoteEditor(){


    return(
        <div className="container">
            <div className={classes.title}>Editor - Fully Remote</div>
            <div className={classes.row}>
                <div className={classes.single}>
                    <div className={classes.text}><span className={classes.bold}>Salary:</span>
                        £60k+ (dependent on experience)
                    </div>
                    <div className={classes.text}><span className={classes.bold}>Benefits:</span>
                        Competitive salary and bonuses, private health insurance, Nest pension scheme, competitive
                        holiday allowance, home office equipment and more (UK)
                    </div>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.single}>
                    <div className={classes.subTitle}>About the role</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae
                        tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare
                        convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu
                        et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={`${classes.text} ${classes.m_btn}`}>Lorem ipsum dolor sit amet consectetur. Nisl
                        integer aliquam tortor
                        aenean id.</div>
                    <ul>
                        <li  className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Libero pulvinar vel eget
                            eget. Massa id tincidunt pretium senectus mauris nibh nullam varius posuere. Quis ante
                            gravida libero sagittis nibh nibh senectus arcu. Enim ultricies et et luctus.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Purus mauris accumsan dui
                            tempus. Viverra arcu odio dignissim adipiscing risus nulla consectetur mattis. Amet.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Tempus dui et magna risus
                            eget in.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Congue diam amet a sagittis
                            luctus cursus varius. Pellentesque semper lorem viverra purus. Maecenas a placerat.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Mauris vulputate et
                            condimentum faucibus. Mauris nisl egestas mattis tellus diam velit elit porta egestas.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Sit nulla dignissim lacus
                            ullamcorper ipsum erat. Maecenas augue ut pretium maecenas in urna est sed. Turpis nunc
                            arcu congue lectus. A vel duis viverra vulputate tortor praesent hac. Pharetra sem ipsum
                            varius in dolor leo egestas amet. Viverra.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Augue euismod nunc
                            fermentum interdum dolor nascetur in. Mauris condimentum duis sit aliquet velit.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Volutpat leo nibh elit
                            sed.
                            Pulvinar ornare risus netus a. Massa nec eu ultrices pellentesque. Feugiat cras.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Nisl risus sit viverra
                            erat ipsum feugiat. Elit at id sociis libero non lectus egestas ultricies nulla. Magna
                            nullam.</li>
                    </ul>
                </div>
                <div className={classes.single}>
                    <div className={classes.subTitle}>Your responsibilities</div>
                    <div className={`${classes.text} ${classes.m_btn}`}>Lorem ipsum dolor sit amet consectetur. Nunc
                        pharetra quam ut dolor tellus.</div>
                    <ul>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. Ipsum cursus metus
                            pharetra et dolor lobortis pharetra. Dolor velit orci ac proin ullamcorper id lacus nullam
                            duis. Ultricies adipiscing commodo feugiat pellentesque dui duis dictum.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Mattis ullamcorper sodales ultrices habitant ornare. Non rhoncus leo sed in quis. Quis
                            eleifend tristique urna.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Sapien netus mauris sed sit in leo. Elementum pellentesque elementum quis urna in. Eu hac
                            tellus hac nunc fringilla. Volutpat elit lobortis est id dolor sed libero lacus faucibus in
                            nunc.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Enim a mollis quis scelerisque scelerisque vitae posuere. Vel vivamus varius egestas eget
                            id vitae est arcu.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Leo quis semper vulputate urna habitant. Condimentum bibendum urna imperdiet cras. Et
                            elementum.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Elit nibh dui in arcu et enim. Sapien facilisis morbi fringilla semper elit egestas non.
                            Vestibulum mauris lectus.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Donec id vehicula mattis gravida. Viverra donec nec et sem ultricies ut. Aliquam nec
                            viverra eu massa nibh. Enim mauris pellentesque consectetur placerat cum tortor tellus in
                            nisi. Leo.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Non ultrices dui pellentesque eget nisl nulla eu pellentesque. Amet gravida faucibus nunc
                            tellus amet. Ultrices adipiscing mauris viverra gravida morbi justo eu. Iaculis tortor tristique in.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Non ultrices dui pellentesque eget nisl nulla eu pellentesque. Amet gravida faucibus nunc
                            tellus amet. Ultrices adipiscing mauris viverra gravida morbi justo eu. Iaculis tortor
                            tristique in.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur. In
                            sed hac ornare ornare egestas cras. Velit adipiscing habitant volutpat fermentum pulvinar.
                            Sem vitae tempor porta eget morbi mauris sapien placerat massa. A laoreet nullam.</li>
                        <li className={`${classes.text} ${classes.m_left}`}>Lorem ipsum dolor sit amet consectetur.
                            Risus cras etiam sapien velit elit.</li>
                    </ul>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.single}>
                    <div className={classes.subTitle}>About News Link</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae
                        tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare
                        convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu
                        et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Nisl integer aliquam tortor
                        aenean id.</div>
                </div>
                <div className={classes.single}>
                    <div className={classes.subTitle}>About our Editorial team</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae
                        tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare
                        convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu
                        et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Nisl integer aliquam tortor
                        aenean id.</div>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.single}>
                    <div className={classes.subTitle}>Our Culture</div>
                    <div className={classes.text}><span className={classes.bold}>Open:</span>
                        we share and collaborate within and across departments
                    </div>
                    <div className={classes.text}><span className={classes.bold}>Rigorous:</span>
                        We like to be clear about what we're doing, and why. Experimentation and peer-review are in our
                        DNA
                    </div>
                    <div className={classes.text}><span className={classes.bold}>One team, one vision:</span>
                        no office politics
                    </div>
                    <div className={classes.text}><span className={classes.bold}>An appreciative environment:</span>
                        expect to hear 'thanks' a lot
                    </div>
                    <div className={classes.text}><span className={classes.bold}>Room to grow:</span>
                        development of skills and responsibilities is encouraged and rewarded
                    </div>
                    <div className={classes.text}><span className={classes.bold}>Virtual:</span>
                        we are today a fully-remote company with occasional in-person get-togethers
                    </div>
                    <div className={classes.text}><span className={classes.bold}>Family-friendly and flexible:</span>
                        we offer flexible working, and understand sometimes personal needs come first
                    </div>
                </div>
                <div className={classes.single}>
                    <div className={classes.subTitle}>Apply now</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae
                        tincidunt risus justo sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare
                        convallis purus. Sit integer et sit sollicitudin etiam euismod auctor. Consequat laoreet arcu
                        et facilisis viverra. Orci donec proin sit eu egestas. Amet mauris.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                    <div className={classes.text}>Lorem ipsum dolor sit amet consectetur. Ut malesuada adipiscing vitae
                        dignissim nunc pellentesque arcu sociis velit. Libero eget eget id a porta placerat tincidunt
                        mattis duis. Sed tempor sed elit id mattis nunc ac. Vulputate.</div>
                </div>
            </div>
            <div style={{display:"flex"}}>
                <button className={classes.applyBtn}>Apply now</button>
            </div>
        </div>
    )
}

export default RemoteEditor;