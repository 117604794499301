import React, {useState, useEffect} from 'react'
import Footer from "../Footer/Footer";
import LogOutModal from "../LogOutModal/LogOutModal";
import RedLetter from '../../assets/icons/RedLetter.svg';
import RedAbout from '../../assets/icons/RedAbout.svg';
import RedDate from '../../assets/icons/RedDate.svg';
import Person from '../../assets/icons/Person.svg';
import PersonRound from '../../assets/icons/PersonRound.svg';
import IconDownWhite from '../../assets/icons/IconDownWhite.svg';
import Search from '../../assets/icons/Search.svg';
import Settings from '../../assets/icons/Settings.svg';
import Out from '../../assets/icons/Out.svg';
import classes from './Layout.module.css';
import {Outlet, ScrollRestoration, useLocation, useNavigate} from "react-router-dom";
import FeedbackModal from "../FeedbackModal/FeedbackModal";



const Layout = () => {

    const navigate=useNavigate();
    const location =useLocation();
    const path=location.pathname;
    const [activeItem, setActiveItem]=useState();
    const [settingsDropdown, setSettingsDropdown]=useState(false);
    const [feedbackModalIsOpen, setFeedbackModalIsOpen]=useState(false);
    const [logOutModalIsOpen, setLogOutModalIsOpen]=useState(false);


    const openFeedbackModal =()=>{
        setFeedbackModalIsOpen(true)
    }
    const closeFeedbackModal =()=>{
        setFeedbackModalIsOpen(false)
    }

    const openLogOutModal =()=>{
        setLogOutModalIsOpen(true)
    }
    const closeLogOutModal =()=>{
        setLogOutModalIsOpen(false)
    }


    let getKey = React.useCallback(
        (location, matches) => {
            let match = matches.find((m) => (m.handle)?.scrollMode);
            if ((match?.handle)?.scrollMode === "pathname") {
                return location.pathname;
            }
            return location.key;
        },
        []
    );

    useEffect(() => {
        setActiveItem(path);
    }, [path]);

    const makeActive =(route)=>{
        setActiveItem(route)
    }
    console.log(path, "path", activeItem, "activeItem")

    const headerItems=[
        {
            title:"Home",
            route:"/"
        },
        {
            title:"News & Trending",
            route:"/news-and-trending"
        },
        {
            title:"Business & Economy",
            route:"/business-and-economy"
        },
        {
            title:"Politics",
            route:"/politics"
        },
        {
            title:"Sport",
            route:"/sport"
        },
        {
            title:"World",
            route:"/world"
        },
        {
            title:"Cryptocurrencies",
            route:"/cryptocurrencies"
        },
    ]




    const renderItems =headerItems.map((item, index)=>(
        <div className={`${classes.headerItem}
         ${activeItem === item.route ? classes.activeItem : ""}`} key={index}
             onClick={()=>{
                    makeActive(item.route);
                    navigate(item.route)
                }}>
            {item.title}</div>
    ))

    const openSettingsDropdown =()=>{
        setSettingsDropdown(!settingsDropdown)
    }

    return (
        <>
            <header>
                <div className={classes.headerUp}>
                    <div className={"container"}>
                        <div className={classes.headerInside}>
                            <div className={classes.headerLeft}>
                                <div style={{display:"flex"}}>
                                    <img className="iconStyle" src={RedDate} alt={""}/>
                                </div>
                                <div className={classes.headerText}>
                                    29. February 2024
                                </div>
                            </div>
                            <div className={classes.headerRight}>
                                <div className={classes.headerRightDiv}>
                                    <div style={{display:"flex"}}>
                                        <img className="iconStyle" src={RedAbout} alt={""}/>
                                    </div>
                                    <div className={classes.headerText}>About </div>
                                </div>
                                <div className={classes.headerRightDiv}>
                                    <div style={{display:"flex"}}>
                                        <img className="iconStyle" src={RedLetter} alt={""}/>
                                    </div>
                                    <div className={classes.headerText}>Contact</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.headerMiddle}>
                    <div className="container">
                        <div>
                            <div className={classes.middleInsideTop}>
                                <div className={classes.logo}>Logo</div>
                                <div className={classes.searchDiv}>
                                    <input className={classes.searchInput} placeholder={"Search"} />
                                    <img style={{cursor:"pointer"}} className="iconStyle" src={Search} alt={""}/>

                                </div>
                                <div className={classes.feedback} onClick={openFeedbackModal}>feedback</div>
                                {localStorage.getItem("token") ?
                                    <div className={classes.headerBtn} onClick={openSettingsDropdown}>
                                        <img src={PersonRound} alt={""} className="icosStyle"/>
                                        <img src={IconDownWhite} alt={""}/>
                                    </div> :
                                    <div className={classes.headerBtn} onClick={()=>navigate('/login')}>
                                        <img src={Person} alt={""} className="icosStyle"/>sign in
                                    </div>
                                }
                            </div>
                            <div className={classes.middleInsideBottom}>{renderItems}</div>
                        </div>
                    </div>
                    {settingsDropdown &&
                        <div className={classes.dropdown}>
                            <div className={classes.singleUp} onClick={()=>{
                                setSettingsDropdown(false);
                                navigate(`/settings`)}}>
                                <img src={Settings} alt={""} className={"iconStyle"}/>
                               Settings
                            </div>
                            <div className={classes.singleDown}
                                 onClick={()=>{
                                     openLogOutModal();
                                    setSettingsDropdown(false);
                                 }}>
                                <img src={Out} alt={""} className={"iconStyle"}/>
                             Log out
                            </div>
                        </div>
                    }
                </div>
            </header>
            <main>
                <Outlet />
            </main>
            <Footer/>
            <ScrollRestoration getKey={getKey} />
            {/*<FeedbackModal feedbackModalIsOpen={feedbackModalIsOpen} closeFeedbackModal={closeFeedbackModal}/>*/}
            {/*<LogOutModal logOutModalIsOpen={logOutModalIsOpen} closeLogOutModal={closeLogOutModal}/>*/}
        </>
    );
}



export default Layout;
