import React from "react";
import classes from '../RemoteEditor/RemoteEditor.module.css';
import styles from './TermsAndConditions.module.css';

function TermsAndConditions(){

    return(
        <div className="container">
            <div className={classes.row}>
                <div className={classes.single}>
                    <div className={classes.title}>Publisher Network Terms and Conditions</div>
                    <div className={styles.subTitle}>Definitions</div>
                    <ul>
                        <li  className={`${classes.text} ${classes.m_left}`}>“We”, “us” or “NewsNow” means NewsNow
                            Publishing Limited.</li>
                        <li  className={`${classes.text} ${classes.m_left}`}>“You” means the person filling in
                            the <span className={styles.red}>NewsNow Publisher Network Application Form,</span>
                            who warrants that they are a representative of the site applying for inclusion on the
                            NewsNow web site, authorised to enter into an agreement with us.</li>
                        <li  className={`${classes.text} ${classes.m_left}`}>“Newsfeed” means an arrangement of
                            headlines, in some cases accompanied by short excerpts from articles, linking to articles,
                            on your web site and other sites.</li>
                        <li  className={`${classes.text} ${classes.m_left}`}>“Services” means our newsfeed delivery
                            services, which deliver lists of relevant article references to our users, whether in
                            emails or using other electronic methods (now or in the future known), including the
                            website at NewsNow.co.uk/NewsNow.net, any international versions of such website and
                            newsfeeds delivered to third-party websites.</li>
                        <li  className={`${classes.text} ${classes.m_left}`}>“Terms” means these terms and conditions,
                            including as updated from time to time.</li>
                    </ul>
                </div>
                <div className={classes.single}>
                    <div className={classes.title}>Terms</div>
                    <ol className={styles.olStyle}>
                        <li className={classes.text}>You agree to abide by, and understand and
                            accept (as appropriate) the<span className={styles.red}>Editorial Standards, Code of Conduct
                                and Issue Resolution Procedures</span>and<span className={styles.red}>Technical
                                Requirements.</span>
                        </li>
                        <li className={classes.text}>You understand and accept that:
                            <ol className={styles.olStyle}>
                                <li className={classes.text}>NewsNow's systems may periodically make requests to your
                                    website to determine the headlines, URLs and contents of articles published;</li>
                                <li className={classes.text}>NewsNow may reproduce headlines and URLs, in some cases
                                    accompanied by short excerpts from articles, within its Services.</li>
                            </ol>
                        </li>
                        <li className={classes.text}>You agree, if requested, to display a specified NewsNow logo on
                            page(s) of your site, above the fold (visible without scrolling) and in a prominent
                            position.</li>
                        <li className={classes.text}>You agree to notify us promptly of any change of ownership of your
                            publication, and/or change of contact details.</li>
                        <li className={classes.text}>You agree to notify us promptly of any change to the design of
                            your site, or any technical changes (such as the introduction of a requirement to register)
                            which may affect our ability to make requests to your web site to determine the headlines,
                            URLs and contents of articles published.</li>
                        <li className={classes.text}>NewsNow's systems will attempt to match articles correctly to
                            relevant Newsfeeds featured in its Services. However, NewsNow cannot guarantee that a link
                            to any given article will be featured in a given Newsfeed on its site, or indeed anywhere
                            on the site.</li>
                        <li className={classes.text}>NewsNow makes no warranty as to the fitness of its Services for
                            any purpose. NewsNow will accept no liability for any loss or damage, whether direct or
                            consequential, resulting from the use of its Services or the inclusion or exclusion of
                            links from its Services.</li>
                        <li className={classes.text}>NewsNow may terminate its relationship with you at any time by
                            removing links to your site from its Services and by stopping its systems from accessing
                            your website to determine the headlines, URLs and contents of articles published. If you
                            wish to terminate your relationship with NewsNow, you may<span className={styles.red}>
                                contact us.</span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions;