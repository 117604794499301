import React, {useState} from 'react';
import SettingsIcon from '../../assets/icons/SettingsIcon.svg';
import SettugsRedIcon from '../../assets/icons/SettingsRedIcon.svg';
import {Outlet, ScrollRestoration, useNavigate} from "react-router-dom";
import classes from './SettingsLayout.module.css';


const publisherDropdownData =[
    {
        name:"Open Content Policy",
        route:"open-content-policy"
    },
    {
        name:"Editorial Standards, Code of Conduct and Issue Resolution Procedures",
        route:"editorial-standards"
    },
    {
        name:"Publisher Network Terms and Conditions" ,
        route:"terms-and-conditions"
    },
    {
        name:"Technical Requirements",
        route:"technical-requirements"
    },
    {
        name:"Applications Guidance",
        route:"applications-guidance"
    }
]


const careersDropdownData =[
    {
        name:"Remote Editor",
        route:"remote-editor"
    },
    {
        name:"Remote Commercial Manager",
        route:"remote-commercial-manager"
    },
    {
        name:"Remote Software Developer" ,
        route:"remote-software-developer"
    },
    {
        name:"Remote Full Stack Developer",
        route:"remote-full-stack-developer"
    }
]
const SettingsLayout = () => {


    const [publisherDropdown, setPublisherDropdown]=useState(false);
    const [careersDropdown, setCareersDropdown]=useState(false);
    const [activeItem, setActiveItem]=useState("");
    const navigate =useNavigate();


    let getKey = React.useCallback(
        (location, matches) => {
            let match = matches.find((m) => (m.handle)?.scrollMode);
            if ((match?.handle)?.scrollMode === "pathname") {
                return location.pathname;
            }
            return location.key;
        },
        []
    );

    const renderPublisherDropdownData=publisherDropdownData.map((item, index)=>(
        <div className={classes.single} key={index} onClick={()=>{
            setPublisherDropdown(false);
            navigate(`/settings/publisher-network/${item.route}`)}}>
            {item.name}
        </div>
    ))

    const renderCareersDropdownData=careersDropdownData.map((item, index)=>(
            <div className={classes.single} key={index} onClick={()=>{
                setCareersDropdown(false);
                navigate(`/settings/careers/${item.route}`)}}>
                {item.name}
            </div>
        ))

    let openPublisherDropdown=()=>{
        setPublisherDropdown(!publisherDropdown)
    }

    let openCareersDropdown=()=>{
        setCareersDropdown(!careersDropdown)
    }

    const makeActive =(name)=>{
        setActiveItem(name)
    }


    return(
        <>
         <main className={classes.settingsWhole}>
            <div className='container'>
                <div className={classes.titleSettings}>Account Settings</div>
                <div className={classes.below}>
                    <div className={classes.itemWhole}>
                        <div className={`${classes.item} ${window.location.pathname.includes("publisher") ? classes.active : ""}`}
                     onClick={()=>{makeActive("publisher");
                     setPublisherDropdown(false);
                     navigate("/settings/publisher-network")}}>Publisher Network</div>
                        <div className="iconStyle"  style={{cursor:"pointer"}} onClick={openPublisherDropdown}>
                            {window.location.pathname.includes("publisher") ? <img className="iconStyle" src={SettugsRedIcon} alt=""/>:
                            <img className="iconStyle" src={SettingsIcon} alt=""/>}
                        </div>
                        {publisherDropdown && 
                            <div className={classes.dropdown}>
                                {renderPublisherDropdownData}
                            </div>
                        }
                    </div>
                    <div className={classes.itemWhole}>
                        <div className={`${classes.item} ${window.location.pathname.includes("careers") ? classes.active : ""}`}
                            onClick={()=>{makeActive("careers");
                            setCareersDropdown(false);
                            navigate("/settings/careers")}}> Careers
                        </div>
                        <div className="iconStyle"  style={{cursor:"pointer"}} onClick={openCareersDropdown}>
                            {window.location.pathname.includes("careers") ? <img className="iconStyle" src={SettugsRedIcon} alt=""/>:
                            <img className="iconStyle" src={SettingsIcon} alt=""/>}
                        </div>
                        {careersDropdown && 
                            <div className={classes.dropdownCareers}>
                                {renderCareersDropdownData}
                            </div>
                        }
                    </div>     
                </div>
            </div>
            <Outlet/>
            </main>
            <ScrollRestoration getKey={getKey} />
        </>
    )
}


export default SettingsLayout;