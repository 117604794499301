import React, {useState} from "react";
import FeedbackIcon1 from '../../assets/icons/FeedbackIcons/FeedbackIcon1.svg';
import FeedbackIcon2 from '../../assets/icons/FeedbackIcons/FeedbackIcon2.svg';
import FeedbackIcon3 from '../../assets/icons/FeedbackIcons/FeedbackIcon3.svg';
import FeedbackIcon4 from '../../assets/icons/FeedbackIcons/FeedbackIcon4.svg';
import FeedbackIcon5 from '../../assets/icons/FeedbackIcons/FeedbackIcon5.svg';
import FeedbackIconBlack1 from '../../assets/icons/FeedbackIcons/FeedbackIconBlack1.svg';
import FeedbackIconBlack2 from '../../assets/icons/FeedbackIcons/FeedbackIconBlack2.svg';
import FeedbackIconBlack3 from '../../assets/icons/FeedbackIcons/FeedbackIconBlack3.svg';
import FeedbackIconBlack4 from '../../assets/icons/FeedbackIcons/FeedbackIconBlack4.svg';
import FeedbackIconBlack5 from '../../assets/icons/FeedbackIcons/FeedbackIconBlack5.svg';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import classes from './FeedbackModal.module.css';

const iconsData=[
    {
        icon:FeedbackIcon1,
        activeIcon:FeedbackIconBlack1,
        showActive:false
    },
    {
        icon:FeedbackIcon2,
        activeIcon:FeedbackIconBlack2,
        showActive:false
    },
    {
        icon:FeedbackIcon3,
        activeIcon:FeedbackIconBlack3,
        showActive:false
    },
    {
        icon:FeedbackIcon4,
        activeIcon:FeedbackIconBlack4,
        showActive:false
    },
    {
        icon:FeedbackIcon5,
        activeIcon:FeedbackIconBlack5,
        showActive:false
    },
]

function FeedbackModal(props){

    const customStyles = {
        content: {
            padding: '40px',
            maxWidth: '515px',
            width: '100%',
            top: '35%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background:'#FFFFFF',
            borderRadius:'16px',
        },
        overlay: {zIndex: 1000}
    };


    const [feedbackIcons, setFeedbackIcons]=useState(iconsData.length ?iconsData : null);
    const { register, handleSubmit, reset, formState: { errors }, getValues  } = useForm({
        defaultValues: {
            full_name: '',
            email: '',
            feedback: '',
        },
        mode: "onSubmit",
    });


    const saveData = (data) => {
        // closeAndReset();
        // props.openSuccessModal()
        console.log(data, "data")
        reset();
    };

    const makeActive=(i)=>{
        console.log(i)
        let feedbackIconsCopy=[...feedbackIcons];
        for(let i=0; i<feedbackIconsCopy.length; i++){
            if(feedbackIconsCopy[i].showActive){
                feedbackIconsCopy[i].showActive= false
            }
        }
        if(feedbackIconsCopy[i].showActive){
            feedbackIconsCopy[i].showActive = false
        }
        else{
            feedbackIconsCopy[i].showActive = true
        }
        setFeedbackIcons(feedbackIconsCopy)
    }

    // const makeActive = (i) => {
    //     console.log(i)
    //     setFeedbackIcons(prevIcons => {
    //         return prevIcons.map((icon, index) => {
    //             if (index === i) {
    //                 return {
    //                     ...icon,
    //                     showActive: !icon.showActive
    //                 };
    //             }
    //             return icon;
    //         });
    //     });
    // };

    const renderIcons =feedbackIcons.map((item, index)=>(
        <div key={index} onClick={()=>makeActive(index)}>
            {item.showActive ?
                <img src={item.activeIcon} className={classes.icon} alt={""}/>
                :
                <img src={item.icon} className={classes.icon} alt={""}/>}
        </div>
    ))


    const closeAndResetModal =()=>{
        reset();
        props.closeFeedbackModal();
        let feedbackIconsCopy=[...feedbackIcons];
        for(let i=0; i<feedbackIconsCopy.length; i++){
            if(feedbackIconsCopy[i].showActive){
                feedbackIconsCopy[i].showActive= false
            }
        }
        setFeedbackIcons(feedbackIconsCopy)
    }

    return(
        <>
            <Modal
                isOpen={props.feedbackModalIsOpen}
                onRequestClose={closeAndResetModal}
                style={customStyles}
                ariaHideApp={false}>
                    <div className="modalInside">
                        <div className="modalTitle maxWidth">Feel free to drop us your
                            feedback</div>
                        <div className={classes.row}>
                            {renderIcons}
                        </div>
                        <form onSubmit={handleSubmit(saveData)}>
                            <div className={classes.inputDiv}>
                                <input
                                    className={`${classes.inputStyle} ${errors.firstName && classes.invalid}`}
                                    {...register("firstName", {
                                        required: 'First name is required'
                                    })}
                                    placeholder="First name"
                                />
                                {errors.firstName && <span className={classes.error}>{errors.firstName.message}</span>}
                            </div>
                            <div className={classes.inputDiv}>
                                <input
                                    className={`${classes.inputStyle} ${errors.surname && classes.invalid}`}
                                    {...register("surname", {
                                        required: 'Surname is required'
                                    })}
                                    placeholder="Surname"
                                />
                                {errors.surname && <span className={classes.error}>{errors.surname.message}</span>}
                            </div>
                            <div className={classes.inputDiv}>
                                  <textarea
                                      className={`${classes.inputStyle} ${errors.feedback && classes.invalid}`}
                                      {...register("feedback", {
                                          required: 'Feedback is required'
                                      })}
                                      placeholder="Feedback"
                                  />
                                {errors.feedback && <span className={classes.error}>{errors.feedback.message}</span>}
                            </div>
                            <button className={classes.btnStyle}>Submit</button>
                        </form>
                    </div>
            </Modal>
        </>
    )
}

export default FeedbackModal;