import React, {useState} from "react";
import DeleteAccountModal from "../../components/DeleteAccountModal/DeleteAccountModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import classes from './Settings.module.css';


function Settings (){

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [deleteAccountModalIsOpen, setDeleteAccountIsOpen]=useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible((prev)=>!prev)
    };

    const openDeleteAccountModal =()=>{
        setDeleteAccountIsOpen(true)
    }
    const closeDeleteAccountModal =()=>{
        setDeleteAccountIsOpen(false)
    }


    return(
        <>
            <div className="container">
                <div className={classes.switcher}>
                    <div>
                        <label className={classes.switch}>
                            <input type="checkbox"/>
                            <span className={`${classes.slider} ${classes.round}`}></span>
                        </label>
                    </div>
                    <div>
                        <div className={classes.titleSwitcher}>Notification switch</div>
                        <div className={classes.textSwitcher}>Tap to switch email notification</div>
                    </div>
                </div>
                <div className={classes.information}>
                    <div className={classes.single}>
                        <div className={classes.title}>Personal information</div>
                        <div className={classes.inputDiv}>
                            <label className={classes.labelStyle}>First name</label>
                            <input
                                className={classes.inputStyle}
                                name="first_name"
                                placeholder="John"/>
                        </div>
                        <div className={classes.inputDiv}>
                            <label className={classes.labelStyle}>Surname</label>
                            <input
                                className={classes.inputStyle}
                                name="surname"
                                placeholder="Doe"/>
                        </div>
                        <button className={classes.btnStyle}>Save</button>
                    </div>
                    <div className={classes.single}>
                        <div className={classes.title}>Password</div>
                        <div className={classes.inputDiv}>
                            <label className={classes.labelStyle}>Email</label>
                            <input
                                className={classes.inputStyle}
                                name="email"
                                placeholder="johndoe123@gmail.com"/>
                        </div>
                        <div className={classes.inputDiv}  style={{position:"relative"}}>
                            <label className={classes.labelStyle}>New Password</label>
                            <input
                                className={classes.inputStyle}
                                name="new_password"
                                type={passwordVisible ? "text" : "password"}
                                placeholder="*************"/>
                            <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye}
                                             style={{ cursor: "pointer", position:"absolute", bottom:"20px", right:"20px" }}
                                             onClick={togglePasswordVisibility} />
                        </div>
                        <button className={classes.btnStyle}>Save</button>
                    </div>
                </div>
                <div className={classes.title} style={{marginBottom:"4px"}}>Delete account</div>
                <div className={classes.subTitle}>If you would like to delete your account, please click on the button below.</div>
                <button className={classes.btnStyle} onClick={()=>openDeleteAccountModal()}>Delete my account</button>
            </div>
            <DeleteAccountModal deleteAccountModalIsOpen={deleteAccountModalIsOpen}
                                closeDeleteAccountModal={closeDeleteAccountModal}/>
            </>
    )
}

export default Settings;