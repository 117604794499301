import React from "react";
import { useNavigate } from "react-router-dom";
import classes from './SettingsPublisher.module.css';


function SettingsPublisher(){

    const navigate=useNavigate();

    
    return(
        <div className="container">
            <div className={classes.row}>
                <div className={classes.left}>
                    <div className={classes.single}>
                        <div className={classes.title}>Publisher Network</div>
                        <div className={classes.text}>Thousands of publishers benefit from NewsNow's circulation of links to their articles to more than 11 million monthly users.</div>
                        <div className={classes.text}>NewsNow's audience substantially comprises ABC decision makers in the UK, Europe and
                        the US. Every month, NewsNow's user-base views billions of headlines and over 70 million articles. NewsNow
                        operates an <span className={classes.underlined} onClick={()=>navigate('/settings/publisher-network/open-content-policy/')}
                        >Open Content Policy,</span> that allows large mainstream publishers, as well as smaller sites and bloggers,
                        access to the benefits of having their links included in our services.
                        </div>
                    </div>
                    <div className={classes.single}>
                        <div className={classes.title}>How to apply</div>
                        <div className={classes.text}>If you are sure you and your site are able to comply with these requirements, please
                         <span className={classes.underlined}>click to read our Applications Guidance.</span> 
                            You will find a link to the application form at the bottom of that page. Please note, due to the high volumes of submissions we receive, we are unable to offer 
                            any assistance to non-qualifying sites.
                       </div>
                    </div>
                </div>
                <div className={classes.left}>
                    <div className={classes.single}>
                        <div className={classes.title}>Our requirements</div>
                        <div className={classes.text}>In order to qualify for inclusion within the NewsNow Publisher Network, your site must
                        be able to demonstrate consistently high quality content for at least the previous six months, and you and your
                        site must be able to comply with <span className={classes.underlined}>our Publisher Network Terms and Conditions,</span>
                        which incorporates our <span className={classes.underlined}>Editorial Standards, Code of Conduct and Issue Resolution Procedures </span>
                        and our <span className={classes.underlined}>Technical Requirements.</span> Some sites may be
                        required to display a NewsNow logo as specified in our <span className={classes.underlined}>Terms.</span></div>
                        <div className={classes.text}>We recognise that these requirements will, for some publishers, be quite demanding. So to 
                        save time, please make sure you have read them through carefully and have satisfied yourself that you and your site are able 
                        to comply with them before applying.
                        </div>
                    </div>
                    <div className={classes.single}>
                        <div className={classes.title}>Notifications and Assistance</div>
                        <ul>
                            <li className={classes.list}>Request a site name/URL change</li>
                            <li className={classes.list}>Notify us of a scheduled redesign</li>
                            <li className={classes.list}>Report a headline we didn't pick up</li>
                            <li className={classes.list}>Request a headline be removed</li>
                            <li className={classes.list}>Enquire about something else</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SettingsPublisher;