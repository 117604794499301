import React from "react";
import classes from './Footer.module.css';



function Footer(){
    return(
        <div>
            <div className={classes.footerUp}>
                <div className={"container"}>
                    <div className={classes.footerInside}>
                        <div className={classes.logo}>LOGO</div>
                        <div>
                            <div className={classes.footerItem}>Home</div>
                            <div className={classes.footerItem}>About us</div>
                            <div className={classes.footerItem}>Contact us</div>
                        </div>
                        <div>
                            <div className={classes.footerItem}>Privacy Policy</div>
                            <div className={classes.footerItem}>Cookie Policy</div>
                            <div className={classes.footerItem}>Privacy Settings</div>
                            <div className={classes.footerItem}>Legal Notice</div>
                        </div>
                        <div>
                            <div className={classes.footerItem}>Publisher Network</div>
                            <div className={classes.footerItem}>Advertise</div>
                            <div className={classes.footerItem}>Dockside Online IDE</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"container"}>
                <div className={classes.footerDown}>© Copyright 1997 - 2024 NewsNow Publishing Limited. All rights reserved. </div>
            </div>
        </div>
    )
}

export default Footer;