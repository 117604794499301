import React from "react";
import classes from './ApplicationGuidance.module.css';

function ApplicationsGuidance(){
    return(
        <div className="container">
            <div className={classes.title}>Applications Guidance</div>
            <div>
                <ol>
                    <div className={classes.row}>
                        <div className={classes.single}>
                            <div className={`${classes.text} ${classes.m_btn}`}>We provide the following guidance for publishers on
                                how we evaluate sites for inclusion on NewsNow:
                            </div>
                            <li className={`${classes.text} ${classes.m_left}`}>The inclusion and removal of sites is a subjective
                                choice made by our editors with the aim of creating a high quality and balanced editorial
                                experience for our readers. We may decline a site because we feel it is poorly written, because
                                it isn't materially different from sites that are already on NewsNow or because it doesn‘t
                                otherwise add value. </li>
                            <li className={`${classes.text} ${classes.m_left}`}>We reject applications from sites that cannot
                                demonstrate consistently high quality content for at least the previous six months. You are welcome
                                to apply when you can do so.
                            </li>
                            <li className={`${classes.text} ${classes.m_left}`}>First impressions are important. If the first two
                                or three articles that we read contain spelling, grammatical or punctuation mistakes, we are likely
                                to decline a site. Likewise, we are likely to reject poorly designed sites.
                            </li>
                            <li className={`${classes.text} ${classes.m_left}`}>While it is not our policy to dictate to publishers
                                what they can and cannot publish on their own sites, it is our concern what stories are published
                                on NewsNow. If a site publishes a mix of content that we do consider suitable for inclusion and
                                content that we don‘t, you must provide a feed containing only the content suitable for inclusion
                                on NewsNow (as defined in our Editorial Standards and Code of Conduct) in your application.
                            </li>
                        </div>
                        <div className={classes.single}>
                            <li className={`${classes.text} ${classes.m_left}`}>Due to the volume of high quality Premier League news already featured on NewsNow,
                                applications from sites publishing news on the Premier League as a whole will be rejected
                                unless they are authoritative and/or of exceptional quality.</li>
                            <li className={`${classes.text} ${classes.m_left}`}>Sites with excessive advertising, or
                                advertising that impinges on the reading of articles, may be declined.</li>
                            <li className={`${classes.text} ${classes.m_left}`}>We only accept applications from sports
                                sites that are able to place a NewsNow logo of our choice on the homepage of their site,
                                above the fold (without the need to scroll) and in a prominent position. By 'above the
                                fold', we mean visible on a standard browser, monitor and font size at 1024 x 768
                                resolution. Furthermore, it must be positioned away from other aggregator logos or,
                                failing that, above any other aggregator logos. Sites that display the logo and are
                                subsequently found to have removed it will be suspended from NewsNow without notice.
                            </li>
                            <li className={`${classes.text} ${classes.m_left}`}>If you haven’t already done so, please
                                read our<span className={classes.redText}>Publisher Network Terms and Conditions</span>
                                (which incorporates our<span className={classes.redText}>Editorial Standards, Code of
                                    Conduct and Issue Resolution Procedures,</span> and our<span
                                    className={classes.redText}>Technical Requirements).</span></li>
                        </div>
                    </div>
                </ol>
                <div className={classes.btnStyle}>Click to apply</div>
            </div>

        </div>
    )
}

export default ApplicationsGuidance;