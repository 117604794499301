import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './OpenContentPolicy.module.css';


function OpenContentPolicy(){


    const navigate=useNavigate();
    return(
        <div className='container'>
            <div className={classes.title}>Open Content Policy</div>
            <div className={classes.row}>
                <div className={classes.single}>
                    <div className={classes.text}>NewsNow regards all websites that publish periodically updated information as
                     potential sources.</div>
                     <div className={classes.text}>In choosing whether a source qualifies for inclusion in its aggregation services, 
                     NewsNow does apply rigorous <span className={classes.underlined} onClick={()=>navigate('/settings/publisher-network/editorial-standards')}
                     >editorial standards.</span></div>
                     <div className={classes.text}>However, NewsNow avoids distinguishing unnecessarily between:</div>
                     <ul>
                        <li className={classes.list}>authors or publishers who call themselves news organisations and those who do not;</li>
                        <li className={classes.list}>authors or publishers with explicit or hidden biases;</li>
                        <li className={classes.list}>authors or publishers that profess political motivations and those that profess to be objective, independent or unbiased;</li>
                        <li className={classes.list}>articles reviewed by editors or those published without review;</li>
                        <li className={classes.list}>factual, speculative, educational, opinion or entertainment pieces, or press releases.</li>
                     </ul>
                     <div className={classes.text}>(NewsNow does however aim to identify those sources which publish press releases, weblogs, or those which require registration or subscription.)</div>
                </div>
                <div className={classes.single}>
                    <div className={classes.text}>NewsNow currently reviews sources for suitability for inclusion on our website as a consequence of:</div>
                    <ul>
                        <li className={classes.list}>a request by a paying customer;</li>
                        <li className={classes.list}>a request by a website user;</li>
                        <li className={classes.list}>a need, that we determine our website users or paying customers have;</li>
                        <li className={classes.list}>a request by a publisher;</li>
                        <li className={classes.list}>a request by a member of the company.</li>
                    </ul>
                    <div className={classes.text}>(However, we don't usually record the reason a site was added unless it was specifically asked for by a client.)</div>
                    <div className={classes.text}>NewsNow reserves the right to decline to add a source at its sole discretion, and NewsNow reserves the right to change this Open Content Policy at any time without notice.</div>
                </div>
            </div>
        </div>
    )
}

export default OpenContentPolicy;