import React from "react";
import Modal from 'react-modal';
import classes from '../LogOutModal/LogOutModal.module.css';


function DeleteAccountModal(props){

    const customStyles = {
        content: {
            padding: '16px',
            maxWidth: '432px',
            width: '100%',
            top: '35%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background:'#FFFFFF',
            borderRadius:'4px',
            boxShadow: "0px 24px 38px 0px rgba(20, 20, 20, 0.12), 0px 9px 46px 0px rgba(20, 20, 20, 0.08), 0px 11px 15px 0px rgba(20, 20, 20, 0.16)",
        },
        overlay: {zIndex: 1000}
    };


    return(
        <Modal
            isOpen={props.deleteAccountModalIsOpen}
            onRequestClose={props.closeDeleteAccountModal}
            style={customStyles}
            ariaHideApp={false}>
            <div className="modalInside">
                <div className={classes.modalTitle}>Are you sure you want to delete your account?</div>
                <div className={classes.buttons}>
                    <button className={classes.btnStyle} onClick={()=>props.closeDeleteAccountModal()}>No</button>
                    <button className={classes.btnStyle} onClick={()=>{
                        props.closeDeleteAccountModal()
                    }}>Yes</button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteAccountModal;
