import React, { useState, useEffect } from "react";
import LoginImage from '../../assets/images/LoginImage.png'
import Left from '../../assets/icons/Left.svg';
import classes from '../Login/Login.module.css';
import {useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Google from "../../assets/icons/Google.svg";



function Login (){


    const navigate =useNavigate();


    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        mode: "onSubmit",
    });



    const saveData = (data) => {
        // closeAndReset();
        // props.openSuccessModal()
        console.log(data, "data")
        reset();
        navigate('/');
        localStorage.setItem("token", "111");
    };


    return(
        <>
            <div className="whole">
                <div className="left">
                    <div>
                        <div className="logo">Logo</div>
                        <div className="title">Welcome back</div>
                        <div className="subTitle">Lorem ipsum dolor sit amet consectetur. Sapien at tortor fringilla cursus
                            maecenas id sit. Vitae nam nulla eget venenatis faucibus nunc diam. Mollis elit id in mauris enim
                            tristique. Adipiscing posuere lacus.</div>
                    </div>
                </div>
                <div className="right">
                    <div className={classes.goHomeDiv} onClick={()=>navigate('/home')}>
                        <div><img className="iconStyle"  src={Left} alt={""}/></div>
                        <div className="goHome">Go back to the Home page</div>
                    </div>
                    <div className={classes.title}>Sign in</div>
                    <form onSubmit={handleSubmit(saveData)}>
                        <div className={classes.inputDiv}>
                            <input
                                className={`${classes.inputStyle} ${errors.email && classes.invalid}`}
                                {...register("email", {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'Invalid email address'
                                    }
                                })}
                                placeholder="Email"
                            />
                            {errors.email && <span className={classes.error}>{errors.email.message}</span>}
                        </div>
                        <div className={classes.inputDiv}>
                            <input
                                className={`${classes.inputStyle} ${errors.password && classes.invalid}`}
                                {...register("password", {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                    },
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+-])[A-Za-z\d@$!%*+?&-]{8,}$/,
                                        message: 'Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit and one special character'
                                    },
                                })}
                                type={"password"}
                                placeholder="Password"
                            />
                            {errors.password && <span className={classes.error}>{errors.password.message}</span>}
                        </div>
                        <div className={classes.alreadyDiv}>
                            <div className={classes.alreadyHave}>Don't have an account?</div>
                            <div className={classes.alreadyBtn} onClick={()=>navigate('/register')}>Sign up</div>
                        </div>
                        <button className={classes.btnStyle}>sign in</button>
                        <div className={classes.lineDivs}>
                            <div className={classes.lineLeft}/>
                            <div className={classes.or}>or</div>
                            <div className={classes.lineLeft}/>
                        </div>
                        <div className={classes.googleBtn}>
                            <img className="iconStyle" src={Google} alt={"googleIcon"}/>
                            <div className={classes.btnInside}>Sign up with Google</div>
                        </div>
                    </form>
                </div>
            </div>
    </>    
    )
}

export default Login;