import React, {useState} from "react";
import classes from '../Home/Home.module.css';
import HomeIcon1 from "../../assets/icons/HomeIcons/HomeIcon1.svg";
import HomeIcon2 from "../../assets/icons/HomeIcons/HomeIcon2.svg";
import HomeIcon3 from "../../assets/icons/HomeIcons/HomeIcon3.svg";
import HomeIcon4 from "../../assets/icons/HomeIcons/HomeIcon4.svg";
import HomeIcon5 from "../../assets/icons/HomeIcons/HomeIcon5.svg";
import HomeIcon6 from "../../assets/icons/HomeIcons/HomeIcon6.svg";
import EnglishFlag from "../../assets/icons/HomeIcons/EnglishFlag.svg";
import Comment from "../../assets/icons/HomeIcons/Comment.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination, Zoom} from "swiper/modules";
import GreyCalendar from "../../assets/icons/HomeIcons/GreyCalendar.svg";
import GreyComment from "../../assets/icons/HomeIcons/GreyComment.svg";
import GreyTime from "../../assets/icons/HomeIcons/GreyTime.svg";
import GreyEye from "../../assets/icons/HomeIcons/GreyEye.svg";
import {useLocation, useNavigate} from "react-router-dom";
import HomeImage1 from "../../assets/images/HomeImage1.png";


let data=[
    {
        mainTitle:"Latest",
        singleData:[
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Biden, Trump holding separate visits to US-Mexico border",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Incredible moment Florida cops rescue 5-year-old girl with autism from dense watery swamp after " +
                    "spotting her from drone",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
        ]
    },
    {
        mainTitle:"Popular",
        singleData :[
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Biden, Trump holding separate visits to US-Mexico border",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Incredible moment Florida cops rescue 5-year-old girl with autism from dense watery swamp after " +
                    "spotting her from drone",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
        ]
    },
    {
        mainTitle: "Todays",
        singleData:[
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Biden, Trump holding separate visits to US-Mexico border",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Incredible moment Florida cops rescue 5-year-old girl with autism from dense watery swamp after " +
                    "spotting her from drone",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
        ]
    },
    {
        mainTitle: "Yesterdays",
        singleData:[
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Idaho stops man's execution after 8 failed lethal injection attempts",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Biden, Trump holding separate visits to US-Mexico border",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
            {
                title:"Incredible moment Florida cops rescue 5-year-old girl with autism from dense watery swamp after " +
                    "spotting her from drone",
                country:"India",
                day:"Today",
                hour:"3h",
                comments:5
            },
        ]
    },
    {

        mainTitle: "Other",
        singleData: [
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Idaho stops man's execution after 8 failed lethal injection attempts",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Biden, Trump holding separate visits to US-Mexico border",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Incredible moment Florida cops rescue 5-year-old girl with autism from dense watery swamp after " +
                    "spotting her from drone",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
            {
                title: "Incredible moment Florida cops rescue 5-year-old girl with autism from dense watery swamp after " +
                    "spotting her from drone",
                country: "India",
                day: "Today",
                hour: "3h",
                comments: 5
            },
        ]
    },
]

const swiperData=[
    {
        image:HomeImage1,
        date:"29 February 2024",
        comment:"0",
        duration:"5min",
        watched:"5123",
        title:"How’s New York in the Christmas time?",
        text:"Quickly coordinate e-business applications through revolutionary catalysts for change. Seamlessly underwhelm optimal testing procedures processes."
    },
    {
        image:HomeImage1,
        date:"5 March 2024",
        comment:"2",
        duration:"10min",
        watched:"3000",
        title:"Hello Spring Time!",
        text:"Quickly coordinate e-business applications through revolutionary catalysts for change. Seamlessly underwhelm optimal testing procedures processes."
    },
    {
        image:HomeImage1,
        date:"25 April 2024",
        comment:"4",
        duration:"6min",
        watched:"8000",
        title:"Lorem Ipsum!",
        text:"Quickly coordinate e-business applications through revolutionary catalysts for change. Seamlessly underwhelm optimal testing procedures processes."
    }
]


function SinglePage(){

    const navigate=useNavigate();
    const location =useLocation();
    const path=location.pathname;
    const [swiper, setSwiper] = useState(null);
    console.log(path, "path");


    const getTitleAndIcon = () => {
        switch (location.pathname) {
            case '/news-and-trending':
                return { title: 'News & Trending', icon: HomeIcon1 };
            case '/business-and-economy':
                return { title: 'Business & Economy', icon: HomeIcon2 };
            case '/politics':
                return { title: 'Politics', icon: HomeIcon3 };
            case '/sport':
                return { title: 'Sport', icon: HomeIcon4 };
            case '/world':
                return { title: 'World', icon: HomeIcon5};
            case '/cryptocurrencies':
                return { title: 'Cryptocurrencies', icon: HomeIcon6};
            default:
                return { title: 'News & Trending', icon:HomeIcon1 };
        }
    };

    const { title, icon } = getTitleAndIcon();
    const nextSlide = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    const prevSlide = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    const renderWholeData=data.map((item, index)=>(
        <div className={classes.wholeDiv} key={index}>
            <div className={classes.titleDiv}>
                <div className={classes.titleLeft}>
                    <div className={classes.title}>{item.mainTitle}</div>
                </div>
            </div>
            <div className={classes.singleDivs}>
                {item.singleData.map((one, idx)=>(
                    <div className={classes.singleDiv} key={idx}>
                        <div><img className="iconStyle" src={EnglishFlag} alt={""}/></div>
                        <div className={classes.singleInside}>
                            <div className={classes.singleTitle}>{one.title}</div>
                            <div className={classes.singleData}>{one.country}<span>{one.day}</span><span>{one.hour}</span></div>
                            <div className={classes.singleBtnsDiv}>
                                <div className={classes.singleBtn}>Idaho</div>
                                <div className={classes.singleBtn}>Crime</div>
                                <div className={classes.singleBtn}>India</div>
                            </div>
                            <div className={classes.singleCommentsDiv}>
                                <div><img className={"iconStyle"} src={Comment} alt={"comment icon"}/>
                                </div>{one.comments}<span style={{marginLeft:"4px"}}>comments</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {item.mainTitle === "Other" && <button className={classes.moreBtn}>More</button>}
        </div>
    ))

    const renderSwiperData=swiperData.map((item, index)=>(
        <SwiperSlide key={index}>
            <div className={classes.homeUp} style={{backgroundImage: `url(${item.image})`}}>
                <div className={"container"}>
                    <div className={classes.homeInside}>
                        <div className={classes.btns}>
                            <div className={classes.btnStyle}>Cities</div>
                            <div className={classes.btnStyle}>Travel</div>
                        </div>
                        <div className={classes.dates}>
                            <img src={GreyCalendar} alt={""} className="iconStyle"/>{item.date}
                        </div>
                        <div className={classes.homeTitle}>{item.title}</div>
                        <div className={classes.homeText}>{item.text}</div>
                        <div className={classes.details}>
                            <div className={classes.detail}>
                                <img src={GreyComment} alt={"comment"} className={classes.detailIcon}/>{item.comment}
                            </div>
                            <div className={classes.detail}>
                                <img src={GreyTime} alt={""} className={classes.detailIcon}/>{item.duration}
                            </div>
                            <div className={classes.detail}>
                                <img src={GreyEye} alt={""} className={classes.detailIcon}/>{item.watched}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="parentBtn">
                <div className="swiper-button-prev-new" onClick={prevSlide}></div>
                <div className="swiper-button-next-new" onClick={nextSlide}></div>
            </div>
        </SwiperSlide>
    ))


    return(
        <div>
            <Swiper
                onSwiper={setSwiper}
                spaceBetween={50}
                slidesPerView={1}
                zoom={true}
                navigation={true}
                modules={[Zoom, Navigation,Pagination]}
                onSlideChange={() => console.log('slide change')}
                className="swiperHome"
            >
                {renderSwiperData}
            </Swiper>
            <div className={"container"}>
                <div className={classes.wholeDivs}>
                    <div className={classes.titleLeftSingle}>
                        <div><img className={classes.bigIcon} src={icon} alt="" /></div>
                        <div className={classes.title}>{title}</div>
                    </div>
                    {renderWholeData}
                </div>
            </div>
        </div>
    )
}

export default SinglePage;