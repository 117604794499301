import React from "react";
import classes from './TechnicalRequirements.module.css';


function TechnicalRequirements(){

    return(
        <div className="container">
            <div className={classes.row}>
                <div className={classes.single}>
                    <div className={classes.block}>
                        <div className={classes.title}>Technical Requirements</div>
                        <div className={classes.text}>Make sure each article you publish has a distinct, or unique,
                            URL. We recommend you insert the date or a unique number into the URL. Ideally, create a
                            folder for each day and number the article pages within this folder sequentially. e.g.
                            http://www.whoyouare.com/news/20061225/a-christmas-story1.html</div>
                    </div>
                    <div className={classes.block}>
                        <div className={classes.title}>1. Technical Requirements</div>
                        <div className={classes.text}>Make sure each article you publish has a distinct, or unique,
                            URL. We recommend you insert the date or a unique number into the URL. Ideally, create
                            a folder for each day and number the article pages within this folder sequentially.
                            e.g. http://www.whoyouare.com/news/20061225/a-christmas-story1.html</div>
                    </div>
                    <div className={classes.block}>
                        <div className={classes.title}>2. Employ distinct and unique headlines for every article</div>
                        <div className={classes.text}>Make sure each article you publish has a unique headline.</div>
                    </div>
                    <div className={classes.block}>
                        <div className={classes.title}>3. Include a URL for an og:image or twitter:image with every
                            article</div>
                        <div className={classes.text}>We will display these images with your article headlines.</div>
                        <div className={classes.text}>The filetype should be either jpeg, png or webp. The recommended
                            minimum width for these images is 480px. The recommended minimum height is 240px. Larger
                            images will look better on higher resolution devices.</div>
                        <div className={classes.text}>Examples of the required meta tags would be:</div>
                        <div className={classes.text}>
                            meta property="og:image" content="https://newsnow.co.uk/article123.jpg"
                        </div>
                        <div className={classes.text}>
                            meta name="twitter:image" content="https://newsnow.co.uk/article123.jpg"
                        </div>
                        <div className={classes.text}>If using og:image, it is helpful if you also include
                            og:image:width and og:image:height meta tags.</div>
                        <div className={classes.redText}>See the Facebook Webmaster Guide for details about og
                            images</div>
                    </div>
                    <div className={classes.block}>
                        <div className={classes.title}>Notes</div>
                        <div className={classes.text}>NewsNow aims to scan your feed site every 10 minutes, however
                            there will be variations in this frequency due to the performance of the internet and the
                            sites we scan, so this is not a guarantee. Thereafter, our automated processes will analyse
                            each new article found, and determine in which of our website topics that article would be
                            most appropriate (though please do not expect to see all your articles appear in any
                            particular website topic).</div>
                        <div className={classes.text}>In the event that our systems are experiencing difficulties in
                            analysing your articles - the most common reason for this is that you have not included
                            Article Start and End tags within your web pages as detailed on step 4 (above) - there may
                            be significant delays before your articles appear on NewsNow, and the breadth of topics in
                            which your article is placed will be considerably reduced.</div>
                    </div>
                </div>
                <div className={classes.single}>
                    <div className={classes.block}>
                        <div className={classes.title}>4. List your articles in an RSS feed, Atom feed, or dedicated
                            headlines webpage</div>
                        <div className={classes.text}>To reduce the load on your server and ensure we are continuously
                            informed of the articles you publish in a timely manner, we require that you provide us
                            with the address of a dedicated headline page for us to scan. This can be an RSS feed, an
                            Atom feed, or a simple HTML page containing just headlines linking to articles.</div>
                        <div className={classes.text}>If you don't already have an RSS or Atom feed, please use the
                            following steps to set up a dedicated headline page for us to scan:</div>
                        <ol>
                            <li className={`${classes.text} ${classes.m_left}`}>Create a dedicated headline web page
                                for our spider to visit, e.g. http://www.whoyouare.com/NewsNow/ For an example
                                visit: <span className={classes.redSpan}>https://www.gold-eagle.com/newsnow.php</span>
                            </li>
                            <li className={`${classes.text} ${classes.m_left}`}>Place on this page the
                                headline-hyperlinks for all articles published, but ensure that the page references at
                                least every article published within the last 24 hours but does not reference any
                                article that is more than sixty days old.</li>
                            <li className={`${classes.text} ${classes.m_left}`}>Make sure the hyperlink text is exactly
                                the headline text. This is the text we will display on NewsNow.co.uk.</li>
                            <li className={`${classes.text} ${classes.m_left}`}>Make sure the hyperlink URL links to
                                precisely the web page containing the article in question.</li>
                            <li className={`${classes.text} ${classes.m_left}`}>Don't place any other links on this
                                page as we instruct our software to harvest everything and this would cause erroneous
                                headlines to appear on our website</li>
                            <li className={`${classes.text} ${classes.m_left}`}>As this page will only be scanned by
                                our spider and not viewed by the public, please remember not to include any graphics or
                                other design elements. This would only increase the load on your servers and ours.</li>
                        </ol>
                        <div className={classes.text}>As an example, your dedicated headline page should look something
                            like this:</div>
                        <div className={classes.text}>
                            Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo
                            sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus.
                        </div>
                        <div className={classes.text}>
                            Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo
                            sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus.
                        </div>
                        <div className={classes.text}>
                            Lorem ipsum dolor sit amet consectetur. Aliquam molestie vitae tincidunt risus justo
                            sollicitudin risus nunc. Id ac sagittis vulputate at. Nullam ac ornare convallis purus.
                        </div>
                    </div>
                    <div className={classes.block}>
                        <div className={classes.title}>5. Insert HTML comment tags that top and tail your article
                            text</div>
                        <div className={classes.text}>Our systems need to be able to analyse the text of each article
                            you publish, in order to determine to which of our feeds they are most relevant. To ensure
                            we can properly identify where on the webpage the article begins and ends, you will need
                            to edit your website templates as described below.
                        </div>
                        <div className={classes.text}>N.B. If you do not do this, or do not do this correctly, your
                            articles will not receive the maximum possible exposure on NewsNow, and their display may
                            be considerably delayed.
                        </div>
                        <ol>
                            <li className={`${classes.text} ${classes.m_left}`}>
                                 &lt;!-- Article Start --&gt;should follow
                                // the headline but immediately precede the first paragraph of the article
                            </li>
                            <li className={`${classes.text} ${classes.m_left}`}>
                                &lt;!-- Article End --&gt;
                                should immediately follow the last paragraph of the article.
                            </li>
                            <li className={`${classes.text} ${classes.m_left}`}>If your articles contain a byline or
                                specify an author then  &lt;  !-- Author Start --&gt; should immediately precede the
                                author's name and &lt; !-- Author Start --&gt; should immediately follow it.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TechnicalRequirements;