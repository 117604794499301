export let HOME = "/" ;
export let REGISTER = "/register" ;
export let LOGIN = "/login" ;
export let VERIFY="/verify" ;
export let NEWS_AND_TRENDING="/news-and-trending";
export let BUSINESS_AND_ECONOMY="/business-and-economy";
export let POLITICS="/politics";
export let SPORT ="/sport";
export let WORLD="/world";
export let CRYPTOCURRENCIES="/cryptocurrencies"
export let SETTINGS ="/settings";
export let SETTINGS_PUBLISHER="/settings/publisher-network";
export let SETTINGS_PUBLISHER_OPEN_CONTENT_POLICY="/settings/publisher-network/open-content-policy";
export let SETTINGS_PUBLISHER_EDITORIAL_STANDARDS="/settings/publisher-network/editorial-standards";
export let SETTINGS_PUBLISHER_TERMS_AND_CONDITIONS="/settings/publisher-network/terms-and-conditions";
export let SETTINGS_PUBLISHER_TECHNICAL_REQUIREMENTS="/settings/publisher-network/technical-requirements";
export let SETTINGS_PUBLISHER_APPLICATIONS_GUIDANCE="/settings/publisher-network/applications-guidance";
export let SETTINGS_CAREERS="/settings/careers";
export let SETTINGS_CAREERS_REMOTE_EDITOR="/settings/careers/remote-editor";
