import React from "react";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,useLocation
} from "react-router-dom";
import Layout from "../components/Layout/Layout";
import SettingsLayout from "../components/SettingsLayout/SettingsLayout";
import {
    HOME,
    REGISTER,
    LOGIN,
    SETTINGS,
    SETTINGS_PUBLISHER,
    SETTINGS_PUBLISHER_OPEN_CONTENT_POLICY,
    SETTINGS_PUBLISHER_EDITORIAL_STANDARDS,
    SETTINGS_CAREERS,
    VERIFY,
    SETTINGS_CAREERS_REMOTE_EDITOR,
    SETTINGS_PUBLISHER_TERMS_AND_CONDITIONS,
    SETTINGS_PUBLISHER_TECHNICAL_REQUIREMENTS,
    SETTINGS_PUBLISHER_APPLICATIONS_GUIDANCE,
    NEWS_AND_TRENDING,
    BUSINESS_AND_ECONOMY,
    POLITICS,
    SPORT,
    WORLD,
    CRYPTOCURRENCIES
} from "./route-path";
import {Login, Verify, Register,Home, Settings, SettingsPublisher, OpenContentPolicy, EditorialStandards,
    RemoteEditor,Careers, TermsAndConditions,TechnicalRequirements,ApplicationsGuidance, SinglePage} from '../pages/index';


const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                path={REGISTER}
                element={<Register/>}
                handle={{ scrollMode: "pathname"}}
            />
            <Route
                path={VERIFY}
                element={<Verify/>}
                handle={{ scrollMode: "pathname"}}
            />
            <Route
                path={LOGIN}
                element={<Login/>}
                handle={{ scrollMode: "pathname"}}
            />
            <Route path={HOME} element={<Layout />}>
                <Route index element={<Home />} />
                <Route path={NEWS_AND_TRENDING} element={<SinglePage />}/>
                <Route path={BUSINESS_AND_ECONOMY} element={<SinglePage />}/>
                <Route path={POLITICS} element={<SinglePage />}/>
                <Route path={SPORT} element={<SinglePage />}/>
                <Route path={WORLD} element={<SinglePage />}/>
                <Route path={CRYPTOCURRENCIES} element={<SinglePage />}/>
                <Route path={SETTINGS} element={<SettingsLayout />}>
                    <Route index element={<Settings />} />
                    <Route path={SETTINGS_PUBLISHER} element={<SettingsPublisher />}/>
                    <Route path={SETTINGS_PUBLISHER_OPEN_CONTENT_POLICY} element={<OpenContentPolicy />} />
                    <Route path={SETTINGS_PUBLISHER_EDITORIAL_STANDARDS} element={<EditorialStandards />} />
                    <Route path={SETTINGS_PUBLISHER_TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
                    <Route path={SETTINGS_PUBLISHER_TECHNICAL_REQUIREMENTS} element={<TechnicalRequirements />} />
                    <Route path={SETTINGS_PUBLISHER_APPLICATIONS_GUIDANCE} element={<ApplicationsGuidance />} />
                    <Route path={SETTINGS_CAREERS} element={<Careers />} />
                    <Route path={SETTINGS_CAREERS_REMOTE_EDITOR} element={<RemoteEditor />} />
                </Route>
            </Route>
        </>
    )
);

export default router;