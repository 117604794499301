import React, { useState, useEffect } from "react";
import classes from '../Login/Login.module.css';
import Left from "../../assets/icons/Left.svg";
import {useNavigate} from "react-router-dom";


function Verify(){

    const navigate =useNavigate();
    const [loginData, setLoginData]=useState({})

    const handleChange=(e)=>{
        const {name, value}=e.target;
        setLoginData((prev)=>({
            ...prev,
            [name]:value
        }))
    }

    const handleSubmitFirst=()=>{
        setLoginData({})
        navigate('/login')
    }

    return(
        <div className="whole">
            <div className={classes.leftImage}></div>
            <div className={classes.rightLogoDiv}>
                <div className={classes.goHomeLoginDiv} onClick={() => navigate('/home')}>
                    <div><img className="iconStyle" src={Left} alt={""}/></div>
                    <div className="goHome">Go back</div>
                </div>
                <div className={classes.loginTitle}>Account Consent</div>
                <div>
                    <div className={classes.inputDiv}>
                        <label className={classes.labelStyle}>Email address</label>
                        <input
                            className={classes.inputStyle}
                            name="email_first"
                            placeholder="johndoe123@gmail.com"
                            onChange={handleChange}
                        />
                        {/* {errors.email && <span className={classes.error}>{errors.email.message}</span>} */}
                    </div>
                    <div className={classes.checkboxPart}>
                        <div className={classes.checkBoxDiv}>
                            <input className={classes.checkboxStyle} type="checkbox" id="import_current_settings"
                                   name="import_current_settings"
                                   onChange={handleChange} value="import_current_settings"/>
                            <label className={classes.checkboxLabel} htmlFor="import_current_settings">Import my current
                                settings<br/>
                                Check this box to save the NewsNow settings, currently stored
                                in your browser, to your new account. This includes your publication filters (if any)
                                and your most visited topics. Please note
                                that we cannot import your list of recently read articles. If you want to start with a
                                clean slate, untick this box.
                            </label>
                        </div>
                        <div className={classes.checkBoxDiv}>
                            <input className={classes.checkboxStyle} type="checkbox" id="news_now" name="news_now"
                                   onChange={handleChange} value="news_now"/>
                            <label className={classes.checkboxLabel} htmlFor="news_now">Please tick this box if you
                                would like NewsNow to use the contact details
                                you have supplied, including your email address, to keep you informed about developments
                                at NewsNow.
                                (We reserve the right to send you emails directly related to the security or maintenance
                                of your
                                account and such emails may include information about other developments at NewsNow or
                                to its
                                services.)
                            </label>
                        </div>
                    </div>
                    <div className={classes.loginText}>By proceeding, you consent to our
                        <span className={classes.underLined}>Legal Notice</span>
                        and acknowledge how we process your personal data and
                        set cookies as described in our<span className={classes.underLined}> Privacy Policy.</span>
                    </div>
                    <button className={classes.btnStyle} onClick={handleSubmitFirst}>create an account</button>
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}

export default Verify;