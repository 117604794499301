import React from 'react';
import classes from './Careers.module.css';


function Careers(){
    
    return(
        <div className='container'>
            <div className={classes.title}>Join us on our mission to make news serve people better</div>
                <div className={classes.row}>
                    <div className={classes.left}>
                        <div className={classes.single}>
                            <div className={classes.subTitle}>Because a healthy society needs a healthy news diet.</div>
                            <div className={classes.text}>NewsNow is a rare UK-based .com success story: a news organisation using 
                            technology to support and promote credible, independent and public interest journalism, via the extremely
                             successful news aggregation and discovery platform NewsNow.co.uk.</div>
                             <div className={classes.text}>ounded in 1997 to democratise and disrupt the market for news, NewsNow has
                              since become a global top 50 news website with millions of loyal users who discover news from thousands
                               of publishers.</div>
                               <div className={classes.underlined}>Learn more about how we operate, our social mission, and ethos.</div>
                        </div>
                        <div className={classes.single}>
                            <div className={classes.subTitle}>Vacancies</div>
                            <div className={classes.text}>Staying at the forefront of our industry and our fields takes huge amounts of 
                            editorial and engineering skill and creativity. We're currently working on many exciting projects and challenges,
                             such as building out and optimising our entirely new design (which represents the biggest upgrade to NewsNow's format 
                             in its 25+ year history) and taking new approaches — including engaging our loyal users — in curating the best and most 
                             diverse news available.</div>
                             <div className={classes.text}>We're looking to hear from people who possess the skills we require to achieve this and 
                             are genuinely excited by our mission.</div>
                            <div className={classes.greyTitle}>Editorial</div>
                            <div className={classes.underlined}>Fully Remote Editor</div>
                            <div className={classes.greyTitle}>Commercial</div>
                            <div className={classes.underlined}>Fully Remote Commercial Manager</div>
                            <div className={classes.greyTitle}>Development</div>
                            <div className={classes.underlined}>Fully Remote Software Developer</div>
                            <div className={classes.underlined}>Fully Remote Software Developer</div>
                        </div>
                    </div>
                    <div className={classes.left}>
                        <div className={classes.single}>
                            <div className={classes.subTitle}>Our Culture</div>
                            <ul>
                                <li className={classes.liStyle}>Open: we share and collaborate within and across departments</li>
                                <li className={classes.liStyle}>Rigorous: We like to be clear about what we're doing, and why. Experimentation and peer-review are in our DNA</li>
                                <li className={classes.liStyle}>One team, one vision: no office politics</li>
                                <li className={classes.liStyle}>An appreciative environment: expect to hear 'thanks' a lot</li>
                                <li className={classes.liStyle}>Room to grow: development of skills and responsibilities is encouraged and rewarded</li>
                                <li className={classes.liStyle}>Virtual: we are today a fully-remote company with occasional in-person get-togethers</li>
                                <li className={classes.liStyle}>Family-friendly and flexible: we offer flexible working, and understand sometimes personal needs come first</li>
                            </ul>
                        </div>
                        <div className={classes.single}>
                            <div className={classes.subTitle}>About NewsNow</div>
                            <div className={classes.text}>NewsNow is a news organisation that uses technology to filter and present news in real time,
                             from thousands of sources, on thousands of topics, to millions of users, 24 hours a day.</div>
                             <div className={classes.text}>NewsNow's site features content on a wide range of subjects, including current affairs,
                              business and entertainment, and we are a go-to destination for football and sports fans. Everything we do is driven by our 
                              core mission, to support and promote credible and independent public interest journalism.</div>
                             <div className={classes.text}>We want to change the way people read the news, getting them to adopt a healthy news diet by 
                             reading widely outside their comfort zone, and we want to foster and support news that is written in the public interest,
                              by publishers who are free, independent and well-supported.</div>
                              <div className={classes.text}>We believe that news is an essential element of a democratic society, and we want to play a 
                              role in making sure it serves that function well. We are a unique fusion of editorial with technology, driven by a desire 
                              to provide quality content, and not by a need to mine our users' attention at all costs. Our technology is always at the
                               service of our editorial standards, and not the reverse.</div>
                              <div className={classes.text}>NewsNow is a family-friendly organisation that recognises the importance of
                               a healthy work-life balance and, along with many other benefits, supports this through a flexible working
                                pattern that allows you to choose when you start and finish work.</div>
                        </div>
                    </div>
                </div>
        </div>

    )
}

export default Careers;